import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  qty;
  totalItem = '';
  totalPrice = '';
  cartData;

  constructor(private productservice: ProductService, private toastr: ToastrService) {}

  ngOnInit() {
    this.getCartData();
  }

  getCartData() {
    this.productservice.getCart().subscribe(
      (res: any) => {
        this.cartData = res.data;
        console.log(this.cartData);
        for (let i = 0; i < this.cartData.length; i++) {
          this.totalItem = (parseFloat(this.totalItem) + parseFloat(this.cartData[i].cart_item_quantity)).toString();
          this.totalPrice = (parseFloat(this.totalPrice) + parseFloat(this.cartData[i].product_price)).toString();
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onClickQuantityminus(qty, data) {
    this.qty = qty;
    this.qty--;
    let cartupdate = { cart_item_id: data.cart_item_id, selling_product_id: data.product_id, quantity: this.qty };

    if (this.qty >= 1) {
      this.productservice.updateCart(cartupdate).subscribe(
        (res: any) => {
          this.getCartData();
        },
        (err) => {},
      );
    }

    if (this.qty == 0) {
      this.productservice.deleteCartItems(cartupdate).subscribe(
        (res: any) => {
          this.getCartData();
        },
        (err) => {
          console.log(err);
        },
      );
    }
  }

  onClickQuantityplus(qty, data) {
    this.qty = qty;
    this.qty++;
    let cartupdate = { cart_item_id: data.cart_item_id, selling_product_id: data.product_id, quantity: this.qty };
    this.productservice.updateCart(cartupdate).subscribe(
      (res: any) => {
        this.getCartData();
      },
      (err) => {},
    );
  }
}
