import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './../app.state';
import { Tutorial } from '../models/userprofile';
import * as TutorialActions from '../actions/profile.actions';
import { Observable } from 'rxjs/Observable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountrycityService } from '../services/countrycity.service';
import { getLocation } from '../lib/utils';
import { Coords } from '../lib/interfaces';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss'],
})
export class EditprofileComponent implements OnInit {
  selectedCountry = '--Choose Country--';
  // userdata = {country: '' , state: '', city: '', address: '', secondary_email: '', profile_image: ''};
  dataofobservable;
  submitted;
  isSubmitting: boolean = false;
  tutorials: Observable<Tutorial[]>;
  coords: Coords =  {lat:0,lng:0};

  constructor(
    private countryService: CountrycityService,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  profileDataForm: FormGroup;

  countries: any;
  states: any;

  profile_image: string = '';
  imgSplit;

  ngOnInit() {
    this.profileDataForm = this.formBuilder.group({
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', Validators.required, Validators.pattern('[a-zA-Z ]*')],
      address: ['', [Validators.required]],
      landmark: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      secondary_email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      profile_image: ['', [Validators.required]],
      profile_imagetemp: ['', [Validators.required]],
      co_ordinates: [[]]
    });
    this.countries = this.countryService.populateCountries();
    this.ongetProfile();
    this.getCurrentLocation();
  }

  async getCurrentLocation(){
    try{
      this.coords = await getLocation();
    }catch(e){
      console.log(e);
      return this.toastr.error(e.message,'Error',{
        disableTimeOut: true
     });
    }
  }

  public onCountryChange(event): void {
    const part = event.target.value.split('$');
    const newVal = part[0];
    this.selectedCountry = part[1];

    this.states = this.countryService.populateStates(newVal);
  }

  onFileChange(base64) {
    this.imgSplit = base64;
    this.profile_image = base64;
  }

  get f() {
    return this.profileDataForm.controls;
  }

  oneditprofile() {
    if (this.imgSplit) {
      const img = this.imgSplit.split('base64,', 2);
      this.profileDataForm.patchValue({ profile_image: img[1] });
    }

    if(!this.coords.lat || !this.coords.lng){
      return this.toastr.error('Please make sure location access allowed','Error',{
         disableTimeOut: true
      });
    }
    this.profileDataForm.patchValue({ co_ordinates: [ this.coords.lat,this.coords.lng ]});

    if (this.profileDataForm.invalid) {
      return;
    }

    this.isSubmitting = true;
    try{
    this
      .authservice
      .editProfile({
        ...this.profileDataForm.value,
        state: this.selectedCountry
      })
      .subscribe((res: any) => {
        this.submitted = true;
        this.isSubmitting = false;

        this.ongetProfile();

        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.router.navigate(['/profile']);
      },
      (err) => {
        console.log({err});
        this.submitted = true;
        this.isSubmitting = false;

        let errors = '';
        if(typeof err.error === 'object'){
          const error = err.error;
          for (const key in error) {
            if (Object.prototype.hasOwnProperty.call(error, key)) {
              const element = error[key];
              errors = error[key].join('\n');
              this.toastr.error(errors, 'Error', {
                timeOut: 9000,
              });
            }
          }
        } else{
          this.toastr.error(err, 'Error', {
            timeOut: 9000,
          });
        }

      }
    );
    }catch(e){
      console.log('on submitting profile: ',e);
    }
  }

  ongetProfile() {
    this.authservice
      .getProfile()
      .subscribe((res: any) => {
        this.profile_image = res.data.profile_image;
        localStorage.setItem('user', JSON.stringify(res.data));
        this.profilecheck();
      },err => {
        console.log('on get profile error',err);
      });
  }

  profilecheck() {
    this.authservice
      .profilecheck()
      .subscribe((res: any) => {
        if (res.type == 'success') {
          this.router.navigate(['/']);
        }
      },err => {
        if (err.error.type == 'user-error') {
          this.router.navigate(['/userupdate']);
        }

        if (err.error.type == 'profile-error') {
          this.router.navigate(['/profile']);
        }
      });
  }
}
