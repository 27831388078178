import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolswappingService } from '../services/toolswapping.service';
// import { google } from 'googlemaps';
import { ProductService } from '../services/product.service';
import { ChatcommunicationService } from '../services/chatcommunication.service';

declare var google: any;

@Component({
  selector: 'app-toolswapping-details',
  templateUrl: './toolswapping-details.component.html',
  styleUrls: ['./toolswapping-details.component.scss'],
})
export class ToolswappingDetailsComponent implements OnInit {
  ToolsDisplayPage = false;
  toolsListingPage = true;
  list = true;
  addtools = false;
  submitted = false;
  selectedCountry: string = '--Choose Country--';
  imagesofPRoducts = [];
  imagesofProductsForDisplay = [];
  country;
  allproducts;
  ToolDisplayData;
  myProducts;
  imageShow;
  productForm: FormGroup;
  rentForm: FormGroup;
  lat;
  lng;
  alltools = true;
  mytools = false;
  rentinit = false;
  rentRequestData;
  rentInitialtedData;
  deliveryAddress;
  deliveryType;
  id;
  type;
  message;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toolswappingservice: ToolswappingService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private productservice: ProductService,
    private chatcommunicationservice: ChatcommunicationService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.paramMap.get('type');

    if (this.type == 'tools') {
      this.getProductById(this.id);
    } else if (this.type == 'mytools') {
      this.getProductById(this.id);
    } else if (this.type == 'init') {
      this.getInitProductById(this.id);
    } else {
      this.getMyRequestById(this.id);
    }

    this.rentForm = this.formBuilder.group({
      listed_id: [''],
      delivery_by_user_id: ['', Validators.required],
      expected_date_of_arrival: [''],
      expect_price_per_day: ['', Validators.required],
      delivery_address_id: [''],
      expected_date_of_return: [''],
      return_by_user_id: [''],
    });
  }

  showimage(index) {
    this.imageShow = this.ToolDisplayData.product_images[index];
  }

  deliverytype() {
    this.deliveryType = this.rentForm.get('delivery_by_user_id').value;
  }

  onRentRequest(ToolDisplayData) {
    this.rentRequestData = ToolDisplayData;
    this.getDeleveryAddress();
  }

  onRequestSubmit() {
    console.log(this.rentRequestData);
    this.rentForm.patchValue({ listed_id: this.rentRequestData.id });
    // this.rentForm.patchValue({expected_date_of_return:});
    const data = JSON.parse(localStorage.getItem('user'));
    this.rentForm.patchValue({ return_by_user_id: data.user_id });
    if (this.rentForm.get('delivery_by_user_id').value == 'self') {
      this.rentForm.patchValue({ delivery_by_user_id: '' });
      this.rentForm.patchValue({ delivery_address_id: '' });
    }

    if (this.rentForm.get('delivery_by_user_id').value == 'owner') {
      this.rentForm.patchValue({ delivery_by_user_id: this.rentRequestData.user_id });
    }

    console.log(this.rentForm.value);

    this.toolswappingservice.productOnRating(this.rentForm.value).subscribe(
      (res: any) => {
        console.log(res);
        this.router.navigate(['/tools']);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  onUpdate(data) {
    this.router.navigate(['/addtools']);
  }

  onDelete(data) {
    this.toolswappingservice.deleteProductById({ id: data.id }).subscribe(
      (res: any) => {
        this.router.navigate(['/tools']);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getDeleveryAddress() {
    this.productservice.getDeleveryAddress().subscribe(
      (res: any) => {
        this.deliveryAddress = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getProductById(id) {
    this.toolswappingservice.getListedProductById({ id: id }).subscribe(
      (res: any) => {
        this.ToolDisplayData = res.data;
        console.log(this.ToolDisplayData);
        this.message = res.data;
        this.imageShow = res.data.product_images[0];
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getInitProductById(id) {
    this.toolswappingservice.getRentInitiatedById({ rent_id: id }).subscribe((res: any) => {
      this.ToolDisplayData = res.data;
      this.message = res.data;
      this.imageShow = res.data.product_images[0];
    });
  }

  getMyRequestById(product_id) {
    this.toolswappingservice.getMyRequestById({ product_id: product_id }).subscribe(
      (res: any) => {
        this.ToolDisplayData = res.data;
        this.message = res.data;
        this.imageShow = res.data.product_images[0];
      },
      (err) => {
        console.log(err);
      },
    );
  }

  letsChatWith(chatdata) {
    console.log(chatdata);
    this.chatcommunicationservice.sendMessage(chatdata);
    this.router.navigate(['/agriexpert']);
  }
}
