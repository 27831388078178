import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { LandService } from '../services/land.service';
import { AgriexpertsService } from '../services/agriexperts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cropmarketplace',
  templateUrl: './cropmarketplace.component.html',
  styleUrls: ['./cropmarketplace.component.scss'],
})
export class CropmarketplaceComponent implements OnInit {
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private landservice: LandService,
    private toastr: ToastrService,
    private agriexpertservice: AgriexpertsService,
  ) {}
  submitted = false;
  lat: number;
  lng: number;
  zoom: number;
  allGlobalCrops; //list of crops
  singleCropData; // single crop
  landReportData; // land report details
  cropProgrssData; // crop progress report
  allglobalcropPage = true;
  cropprogressUpdatePage = false;
  displayReport = false;
  private geoCoder;
  cropValidationForm: FormGroup;
  cropBookForm: FormGroup;

  ngOnInit() {
    this.cropBookForm = this.formBuilder.group({
      cropid: ['', Validators.required],
      quantity: ['', Validators.required],
      payment_type: ['', Validators.required],
      amount: ['', Validators.required],
    });

    this.cropValidationForm = this.formBuilder.group({
      crop_progress_id: [''],
      soil_health_check_validated: [''],
      sowing_validated: [''],
      water_fulfilled_validated: [''],
      pesticides_applied_validated: [''],
    });

    this.getCropProgrssReport();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'],
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        // this.getAddress(this.lat, this.lng);
      });
    }
  }

  getCropById(list) {
    // this.landservice.getCropById({crop_id: id}).subscribe((res:any)=>{

    this.singleCropData = list;
    console.log(this.singleCropData);
    console.log(this.singleCropData.soil_health_check);
    this.displayReport = true;
    // this.getCropProgrssReport();
    this.allglobalcropPage = false;
    // }, err=>{
    //   console.log(err);
    // });
  }

  getLandReport(id) {
    this.landservice.getLandReport({ land_id: id }).subscribe((res: any) => {
      this.landReportData = res.data;
      console.log(this.landReportData);
    });
  }

  getCropProgrssReport() {
    this.landservice.getAllcropProgress().subscribe(
      (res: any) => {
        console.log(res);
        this.allGlobalCrops = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
    console.log('hello');

    // this.landservice.getProgressReport({crop_id:id}).subscribe((res:any) => {
    //   console.log(res.data);
    //   this.cropProgrssData = res.data;
    // }, err => {
    //   console.log(err);
    // })
  }

  CropProgrssUpdate() {
    this.cropprogressUpdatePage = true;
  }

  cropProgressValidation() {
    console.log(this.cropValidationForm.value);
    this.cropValidationForm.patchValue({ crop_progress_id: this.singleCropData.id });
    this.landservice.cropValidation(this.cropValidationForm.value).subscribe(
      (res: any) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  get facceptreport() {
    return this.cropBookForm.controls;
  }

  cropBook() {
    this.submitted = true;

    this.cropBookForm.patchValue({ cropid: this.singleCropData.crop.crop_id });

    if (this.cropBookForm.invalid) {
      return;
    }
    this.landservice.bookcrop(this.cropBookForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
      },
    );
  }

  openProfile(profile) {
    console.log(profile);
  }
}
