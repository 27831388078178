import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDatausers',
})
export class UsersearchPipe implements PipeTransform {
  transform(items: any[], searchTagdata: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchTagdata) {
      return items;
    }
    searchTagdata = searchTagdata.toLowerCase();
    return items.filter((it) => {
      console.log(it);
      if (it.name) {
        if (it.name.includes(searchTagdata)) {
          return it;
        }
      }
    });
  }
}
