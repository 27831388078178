import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recentchatsearch',
})
export class RecentchatsearchPipe implements PipeTransform {
  userdata;

  transform(items: any[], searchChat: string): any[] {
    this.userdata = JSON.parse(localStorage.getItem('user'));
    if (!items) {
      return [];
    }
    if (!searchChat) {
      return items;
    }
    searchChat = searchChat.toLowerCase();

    return items.filter((it) => {
      if (it.chat_thread_user_second.user_id != this.userdata.user_id) {
        if (it.chat_thread_user_second.name.includes(searchChat) || it.sender.message.includes(searchChat)) {
          return it;
        }
      }

      if (it.chat_thread_user_one.user_id != this.userdata.user_id) {
        if (it.chat_thread_user_second.name.includes(searchChat) || it.sender.message.includes(searchChat)) {
          return it;
        }
      }
    });
  }
}
