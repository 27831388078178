import { Component, OnInit, HostListener } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {
  @HostListener('window:scroll', [])
  onWindowScroll() {}

  public handleScroll(event: ScrollEvent) {
    if (event.isReachingBottom) {
    }
    if (event.isReachingTop) {
    }
    if (event.isWindowEvent) {
    }
  }

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor(config: NgbCarouselConfig) {
    //
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    const number = event.path[1].scrollY;
    const abc = typeof 650;
    if (number == 650) {
    }
  };
  ngAfterViewInit(): void {
    const content = document.querySelector('.animated');
    const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));

    scroll$.subscribe((element) => {});
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
}
