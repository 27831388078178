import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListlandComponent } from './listLand/listland.component';
import { SeemarketpricesComponent } from './seemarketprices/seemarketprices.component';
import { ListcropComponent } from './listcrop/listcrop.component';
import { DiscussionsideComponent } from './discussionside/discussionside.component';
import { VofqaComponent } from './vofqa/vofqa.component';
import { BookwarehouseComponent } from './bookwarehouse/bookwarehouse.component';
import { BuyseedsandfertilizersComponent } from './buyseedsandfertilizers/buyseedsandfertilizers.component';
import { ProfileupdateComponent } from './userupdate/profileupdate.component';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { KycComponent } from './kyc/kyc.component';
import { AddsellingproductComponent } from './addsellingproduct/addsellingproduct.component';
import { AdddeliveryaddressComponent } from './adddeliveryaddress/adddeliveryaddress.component';
import { CartComponent } from './cart/cart.component';
import { PricePredictionComponent } from './price-prediction/price-prediction.component';
import { AgriexpertComponent } from './agriexpert/agriexpert.component';
import { AddToolsforRentComponent } from './add-toolsfor-rent/add-toolsfor-rent.component';
import { ToolSwappingComponent } from './tool-swapping/tool-swapping.component';
import { ToolswappingDetailsComponent } from './toolswapping-details/toolswapping-details.component';
import { AddCropsComponent } from './add-crops/add-crops.component';
import { CropmarketplaceComponent } from './cropmarketplace/cropmarketplace.component';
const routes: Routes = [
  { path: '', component : HomeComponent },
  { path: 'home', component : HomeComponent },
  { path: 'listland', component: ListlandComponent },
  { path: 'seemarketprice', component: SeemarketpricesComponent },
  { path: 'listcrop', component: ListcropComponent },
  { path: 'discussionside/:id', component: DiscussionsideComponent },
  { path: 'vofFAQ', component: VofqaComponent },
  { path: 'bookwarehouse', component: BookwarehouseComponent },
  { path: 'buyseeds', component: BuyseedsandfertilizersComponent },
  { path: 'userupdate', component: ProfileupdateComponent },
  { path: 'profile', component: EditprofileComponent },
  { path: 'kyc', component: KycComponent },
  { path: 'addproduct', component: AddsellingproductComponent },
  { path: 'adddelivery', component: AdddeliveryaddressComponent },
  { path: 'cart', component: CartComponent },
  { path: 'pricepredictor', component: PricePredictionComponent },
  { path: 'agriexpert', component: AgriexpertComponent },
  { path: 'addtools', component: AddToolsforRentComponent },
  { path: 'tools', component: ToolSwappingComponent },
  { path: 'toolsdetails/:type/:id', component: ToolswappingDetailsComponent },
  { path: 'newcrop', component: AddCropsComponent},
  {path: 'cropmarketplace', component:CropmarketplaceComponent}

  // { path: 'login', component: LoginComponent },
  // { path: 'signup', component: SignupComponent },
  // { path: 'forgotpassword', component: ForgotpasswordComponent },
  // { path:'farmerdetails',component:FarmerdetailsComponent },
  // { path:'seemarketprice',component:SeemarketpricesComponent },
  // {path:'listcrop',component:ListcropComponent},
  // {path:'discussionside/:id',component:DiscussionsideComponent},
  // {path:'vofFAQ',component:VofqaComponent},
  // {path:'bookwarehouse', component:BookwarehouseComponent},
  // {path:'buyseeds',component:BuyseedsandfertilizersComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
