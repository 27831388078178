import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { pricePredictor } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricePredictorService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    const body = res;
    console.log('predict : ', body);
    return body || {};
  }

  predict(data) {
    return this.http.post(pricePredictor.apiUrlbackend + 'predict', data).pipe(map(this.extractData));
  }
}
