import { Component, Input, OnInit, NgZone } from '@angular/core';
import { element } from 'protractor';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
// import { google } from 'googlemaps';
import { Store } from '@ngrx/store';
import { AppState } from './../app.state';
import { Tutorial } from '../models/userprofile';
import * as TutorialActions from '../actions/profile.actions';
import { Observable } from 'rxjs/Observable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserType } from '../constants/user.constants';
import { getLocation } from '../lib/utils';

declare var google: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userTypes = UserType;
  signupForm: FormGroup;
  submitted = false;
  usertype;
 
  eraSwaploginData = {
    wallet_address: '',
    user_type: '',
    co_ordinates: '',
  };

  lat: any;
  lng: any;
  accuracy: any;
  dataofobservable;
  tutorials: Observable<Tutorial[]>;
  
  profile = true;
  profileData;
  userType;
  isLoggedIn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authservice: AuthService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private userService: UserService,
  ) {}

  ngOnInit() {
    if (this.userService.getUser()) {
      this.isLoggedIn = true;
      this.userType = this.userService.getUserType();
    }
    
    this.setLocation()
  }

  async setLocation(){
    try{
      const coords = await getLocation();
      this.lat = coords.lat;
      this.lng = coords.lng;
    }catch(e){
      console.log(e);
    }
  }
  
  openEraLife() {
    window.open('https://eraswap.life', '', 'width=1003,height=650');
    window.addEventListener(
      'message',
      function (e) {
        ProcessParentMessage_2(e.data);
      },
      false,
    );

    const ProcessParentMessage_2 = (message) => {
      if (message.substring) {
        if (message.substring(0, 2) == '0x') {
          const wallet = new ethers.Wallet(message);
          this.eraSwaploginData.user_type = localStorage.getItem('usertypeid');
          this.eraSwaploginData.wallet_address = wallet.address.toLowerCase();
          this.eraSwaploginData.co_ordinates = this.lat + ',' + this.lng;
          this.walletlogin(this.eraSwaploginData);
        }
      }
    };
  }

  walletlogin(data) {
    this.authservice
      .walletLogin(data)
      .subscribe(
        (res: any) => {
          this.userService.setToken(res.token.token);
          this.isLoggedIn = true;
          this.profilecheck();
        },
        (err) => {
          console.log(err);
        },
      );
  }

  profilecheck() {
    this.authservice
      .profilecheck()
      .subscribe(
        (res: any) => {
          if (res.type == 'success') {
            this.ongetProfile();
          }
        },
        (err) => {
          console.log(err);
          if (err.error.type == 'user-error') {
            this.router.navigate(['/userupdate']);
          }
          if (err.error.type == 'profile-error') {
            this.router.navigate(['/profile']);
          }
        },
      );
  }

  ongetProfile() {
    this.authservice.getProfile().subscribe((res: any) => {
      localStorage.setItem('userType', res.data.user_type);

      this.userService.setUser(JSON.stringify(res.data));
      this.router.navigate(['/home']);
    });
  }

  
  logoutclose() {
    this.userService.logout();
    this.isLoggedIn = false;
    this.router.navigate(['/home']);
  }

  checkNavAccess(allowedUserTypes: []){
    //@ts-ignore
    return allowedUserTypes.includes(this.userService.getUserType());

  }
}
