import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss'],
})
export class ProfileupdateComponent implements OnInit {
  usertype;
  usertypeselected;

  constructor(private formBuilder: FormBuilder, private authservice: AuthService, private router: Router, private toastr: ToastrService) {}

  userDataForm: FormGroup;
  submitted: boolean = false;
  isSubmitting: boolean = false;

  ngOnInit() {
    this.userDataForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      middle_name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      last_name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(12)]],
      user_type: ['', Validators.required],
    });

    this.getUserType(); 
    this.profilecheck();
  }

  get f() {
    return this.userDataForm.controls;
  }

  userUpdate() {
    this.submitted = true;
    console.log('this.userDataForm',this.userDataForm)
    if (this.userDataForm.invalid) {
      return;
    }
    this.isSubmitting = true;
    this.userDataForm.patchValue({ user_type: this.userDataForm.get('user_type').value.id });
    this.authservice
      .userUpdate(this.userDataForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.isSubmitting = false;
          this.profilecheck();
        },
        (err) => {
          console.log(err);
          this.isSubmitting = false;
          this.toastr.error(err.error.email, 'Error', {
            timeOut: 9000,
          });
          this.toastr.error(err.error.mobile_number, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  profilecheck() {
    this.authservice
      .profilecheck()
      .subscribe((res: any) => {
        console.log(res);
        if (res.type == 'user-error') {
          this.router.navigate(['/userupdate']);
        }
      }, err => {
        if (err.error.type == 'profile-error') {
          this.router.navigate(['/profile']);
        }
      });
  }

  getUserType() {
    this.authservice
      .getUserType()
      .subscribe((res: any) => {
        this.usertype = res.data;
      });
  }
}
