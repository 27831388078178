import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SeemarketpricesComponent } from './seemarketprices/seemarketprices.component';
import { ListlandComponent } from './listLand/listland.component';
import { ListcropComponent } from './listcrop/listcrop.component';
import { BookwarehouseComponent } from './bookwarehouse/bookwarehouse.component';
import { DiscussionsideComponent } from './discussionside/discussionside.component';
import { VofqaComponent } from './vofqa/vofqa.component';
import { BuyseedsandfertilizersComponent } from './buyseedsandfertilizers/buyseedsandfertilizers.component';
import { AuthService } from './services/auth.service';
import { LandService } from './services/land.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { ProfileupdateComponent } from './userupdate/profileupdate.component';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { KycComponent } from './kyc/kyc.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { AddsellingproductComponent } from './addsellingproduct/addsellingproduct.component';
import { AdddeliveryaddressComponent } from './adddeliveryaddress/adddeliveryaddress.component';
import { CartComponent } from './cart/cart.component';
import { SearchDataPipe } from './vofqa/search-data.pipe';
import { PricePredictionComponent } from './price-prediction/price-prediction.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ScrollEventModule } from 'ngx-scroll-event';
import { AgriexpertComponent } from './agriexpert/agriexpert.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducers/profile.reducer';
import { AddToolsforRentComponent } from './add-toolsfor-rent/add-toolsfor-rent.component';
import { SearchPipe } from './agriexpert/search.pipe';
import { RatingModule } from 'ngx-rating';
import { agmKey } from '../environments/environment';
import { ToolSwappingComponent } from './tool-swapping/tool-swapping.component';
import { ToolswappingDetailsComponent } from './toolswapping-details/toolswapping-details.component';
import { AddCropsComponent } from './add-crops/add-crops.component';
import { CropmarketplaceComponent } from './cropmarketplace/cropmarketplace.component';
import { UsersearchPipe } from './listcrop/usersearch.pipe';
import { RecentchatsearchPipe } from './agriexpert/recentchatsearch.pipe';
import { InputImageComponent } from './components/input-image/input-image.component';
import { HttpCallsInterceptor } from './lib/http-calls.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SeemarketpricesComponent,
    ListlandComponent,
    ListcropComponent,
    BookwarehouseComponent,
    DiscussionsideComponent,
    VofqaComponent,
    BuyseedsandfertilizersComponent,
    ProfileupdateComponent,
    EditprofileComponent,
    KycComponent,
    AddsellingproductComponent,
    AdddeliveryaddressComponent,
    CartComponent,
    SearchDataPipe,
    PricePredictionComponent,
    AgriexpertComponent,
    AddToolsforRentComponent,
    SearchPipe,
    ToolSwappingComponent,
    ToolswappingDetailsComponent,
    AddCropsComponent,
    CropmarketplaceComponent,
    UsersearchPipe,
    RecentchatsearchPipe,
    InputImageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    CommonModule,
    RatingModule,
    BrowserAnimationsModule,
    NgxTagsInputModule,
    StoreModule.forRoot({
      tutorial: reducer,
    }),
    AgmCoreModule.forRoot({
      apiKey: agmKey.key,
    }),
    ScrollEventModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [
    AuthService, 
    LandService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCallsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
