import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiscussionFormService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getlistForm() {
    return this.http.get(VofBackend.apiUrlbackend + 'forums?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  AddForum(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'addforum?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  myForum() {
    return this.http.get(VofBackend.apiUrlbackend + 'myforum?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  deletemyForum(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'deletemyforum?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  updateForum(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updateforum?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getFoumById(data) {
    return this.http
      .get(VofBackend.apiUrlbackend + 'forum/' + data + '?token=' + localStorage.getItem('token'))
      .pipe(map(this.extractData));
  }

  forumvote(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'forumvote?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  forumComment(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'comment?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  deleteComment(data) {
    return this.http
      .get(VofBackend.apiUrlbackend + 'deletecomment/' + data + '?token=' + localStorage.getItem('token'))
      .pipe(map(this.extractData));
  }

  updatecomment(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updatecomment?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  commentVote(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'commentvote?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  myAnswers() {
    return this.http.get(VofBackend.apiUrlbackend + 'myanswers?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }
}
