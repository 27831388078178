import { Component, OnInit, ɵConsole, Output } from '@angular/core';
import { AgriexpertsService } from '../services/agriexperts.service';
import { EventEmitter } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ChatcommunicationService } from '../services/chatcommunication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agriexpert',
  templateUrl: './agriexpert.component.html',
  styleUrls: ['./agriexpert.component.scss'],
})
export class AgriexpertComponent implements OnInit {
  messages: any[] = [];
  subscription: Subscription;
  agriexportList;
  chatWith;
  message;
  allmessages;
  recentChat;
  userdata;
  feedbackUserData;
  searchInputData;
  userData = { to_user_id: '', ratings: '', feedback: '' };
  Data;
  feedback = false;
  messaging = true;
  threadid;
  allUserList;

  constructor(
    private router: Router,
    private agriexpertsservice: AgriexpertsService,
    private toastrservice: ToastrService,
    private chatcommunicationservice: ChatcommunicationService,
  ) {}

  ngOnInit() {
    this.subscription = this.chatcommunicationservice.getMessage().subscribe((message) => {
      if (message) {
        this.messages.push(message);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
    this.userdata = JSON.parse(localStorage.getItem('user'));
    this.recentChats();
  }

  openForChat(agriList) {
    this.chatWith = agriList;
    console.log(this.chatWith);
    this.allmessages = [];
  }

  onClickSend() {
    if (this.message) {
      this.agriexpertsservice.createThreadChat({ receiver_id: this.chatWith.id, message: this.message, images: '' }).subscribe(
        (res: any) => {
          console.log(res.data);
        },
        (err) => {
          console.log(err);
        },
      );

      this.agriexpertsservice.sendMessage({ chat_thread_id: this.threadid, message: this.message }).subscribe(
        (res: any) => {
          this.allmessages = res.messages;

          this.agriexpertsservice.getMessage({ chat_thread_id: this.threadid }).subscribe(
            (res: any) => {
              this.allmessages = res.data.messages.reverse();
              let size = this.allmessages.length;
              for (let i = 0; i < size; i++) {
                if (this.allmessages[i].is_read == 0 && this.allmessages[i].user_id != this.userdata.user_id) {
                  this.agriexpertsservice
                    .readMessage({ chat_thread_id: this.threadid, message_id: this.allmessages[i].message_id })
                    .subscribe((res: any) => {});
                }
              }
            },
            (err) => {
              console.log(err);
            },
          );
        },
        (err) => {
          console.log(err);
        },
      );
      this.message = '';
    }
  }

  recentChats() {
    this.agriexpertsservice.getChatThread().subscribe(
      (res: any) => {
        console.log(res.data);
        this.recentChat = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  allAgriexperts() {
    this.recentChat = [];
    this.agriexpertsservice.getAgriExpertsList().subscribe(
      (res: any) => {
        this.agriexportList = res.data;
        console.log(this.agriexportList);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  openForChatrecent(recentchat) {
    console.log(recentchat);
    if (recentchat.chat_thread_user_second.user_id != this.userdata.user_id) {
      this.chatWith = recentchat.chat_thread_user_second;
    } else {
      this.chatWith = recentchat.chat_thread_user_one;
    }

    console.log(this.chatWith);
    this.threadid = recentchat.id;
    this.agriexpertsservice.getMessage({ chat_thread_id: recentchat.id }).subscribe(
      (res: any) => {
        this.allmessages = res.data.messages.reverse();
        let size = this.allmessages.length;
        for (let i = 0; i < size; i++) {
          if (this.allmessages[i].is_read == 0 && this.allmessages[i].user_id != this.userdata.user_id) {
            this.agriexpertsservice
              .readMessage({ chat_thread_id: recentchat.id, message_id: this.allmessages[i].message_id })
              .subscribe((res: any) => {
                recentchat.unread_count = 0;
              });
          }
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  feadbackAndRating() {
    this.agriexpertsservice.ratingsAndFeedback({}).subscribe(
      (res: any) => {},
      (err) => {
        console.log(err);
      },
    );
  }

  feedbackdata() {
    this.feedbackUserData = this.chatWith;
    this.messaging = false;
    this.feedback = true;
    this.getAllFeedbacks();
  }

  onSubmitFeedback() {
    this.userData.to_user_id = this.feedbackUserData.user_id;
    this.agriexpertsservice.ratingsAndFeedback(this.userData).subscribe(
      (res: any) => {
        this.toastrservice.success(res.message, 'success', {
          timeOut: 9000,
        });

        this.getAllFeedbacks();

        if (res.type == 'error') {
        } else {
          this.Data = res.data;
        }
      },
      (err) => {
        this.toastrservice.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
        console.log(err);
      },
    );
  }

  getAllFeedbacks() {
    this.agriexpertsservice.getallFeedback({ user_id: this.feedbackUserData.user_id }).subscribe(
      (res: any) => {
        this.Data = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getallUsers() {
    this.agriexpertsservice.getAllUsers().subscribe(
      (res: any) => {
        console.log(res.data);
        this.allUserList = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }
}
