import { Action } from '@ngrx/store';
import { Tutorial } from '../models/userprofile';
import * as TutorialActions from '../actions/profile.actions';

// Section 1
const initialState: Tutorial = {
  name: '',
  url: '',
};

// Section 2
export function reducer(state: Tutorial[] = [initialState], action: TutorialActions.Actions) {
  // Section 3
  switch (action.type) {
    case TutorialActions.ADD_TUTORIAL:
      return [...state, action.payload];
    default:
      return state;
  }
}
