import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';
import { handleError } from '../lib/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getUserType() {
    return this.http.get(VofBackend.apiUrlbackend + 'usertypes').pipe(map(this.extractData));
  }

  walletLogin(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'walletlogin', data).pipe(map(this.extractData));
  }

  userUpdate(data) {
    console.log(data);
    console.log(localStorage.getItem('token'));
    return this.http.post(VofBackend.apiUrlbackend + 'updateuser?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  profilecheck() {
    return this.http.get(VofBackend.apiUrlbackend + 'profilecheck?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  register(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'register', data).pipe(map(this.extractData));
  }

  login(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'login', data).pipe(map(this.extractData));
  }

  passwordChange(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'updatepassword?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  editProfile(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'profile?token=' + localStorage.getItem('token'), data)
    // .pipe(catchError(handleError))
    .pipe(map(this.extractData));
  }

  getProfile() {
    return this.http.get(VofBackend.apiUrlbackend + 'profile?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getKyc() {
    return this.http.get(VofBackend.apiUrlbackend + 'kyc?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  updateKyc(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'updatekyc?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }
}
