import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchChat',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchChat: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchChat) {
      return items;
    }
    searchChat = searchChat.toLowerCase();
    return items.filter((it) => {
      if (it.name.includes(searchChat)) {
        return it;
      }
    });
  }
}
