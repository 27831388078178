import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolswappingService } from '../services/toolswapping.service';
import { ToolSwappingComponent } from '../tool-swapping/tool-swapping.component';

declare var google: any;

@Component({
  selector: 'app-add-toolsfor-rent',
  templateUrl: './add-toolsfor-rent.component.html',
  styleUrls: ['./add-toolsfor-rent.component.scss'],
})
export class AddToolsforRentComponent implements OnInit {
  submitted = false;
  imagesofPRoducts = [];
  imagesofProductsForDisplay = [];
  productForm: FormGroup;
  lat: any;
  lng: any;
  message;

  constructor(
    private formBuilder: FormBuilder,
    private toolswappingservice: ToolswappingService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      landmark: ['', Validators.required],
      postal_code: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      co_ordinates: ['', Validators.required],
      price_per_day: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      product_images: ['', Validators.required],
      delivery_charge_perkm: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      id: [''],
      imgLength: ['', Validators.required],
    });
  }

  get f() {
    return this.productForm.controls;
  }

  onSubmit() {
    if (this.imagesofPRoducts.length > 2) {
      this.productForm.patchValue({ imgLength: this.imagesofPRoducts.length });
    }
    let str = this.imagesofPRoducts.toString();
    this.productForm.patchValue({ product_images: str });

    this.submitted = true;

    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;

        console.log(this.lat);
        console.log(this.lng);
      });
    }
    this.productForm.patchValue({ co_ordinates: this.lat + ',' + this.lng });
    if (this.productForm.invalid) {
      return;
    }
    this.productForm.get('imgLength').disable();

    if (this.productForm.get('id').value) {
      this.toolswappingservice.updateProductById(this.productForm.value).subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
          this.router.navigate(['/tools']);
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      console.log(this.productForm.value);
      this.toolswappingservice.listProductRent(this.productForm.value).subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
          this.router.navigate(['/tools']);
          this.productForm.reset();
        },
        (err) => {
          console.log(err);
        },
      );
    }
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        let img = e.target.result.split('base64,', 2);
        if (this.imagesofPRoducts.length > 0) {
          this.imagesofPRoducts[this.imagesofPRoducts.length] = img[1];
          this.imagesofProductsForDisplay[this.imagesofProductsForDisplay.length] = e.target.result;
        } else {
          this.imagesofPRoducts[0] = img[1];
          this.imagesofProductsForDisplay[0] = e.target.result;
        }
      };
    }
  }

  onUpdate(data) {
    this.productForm.patchValue(data);
  }
}
