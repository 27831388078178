import { Injectable } from '@angular/core';
import { TOKEN, USER } from '../constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any | null;
  token: string | null;

  constructor() {
    if ('localStorage' in window) {
      this.user = JSON.parse(localStorage.getItem(USER));
      this.token = localStorage.getItem(TOKEN);
    }
  }

  getUser() {
    return this.user;
  }

  getUserType(): string {
    return (this.user && this.user.user_type) || '';
  }

  getToken() {
    return this.token;
  }

  setUser(user: any) {
    this.user = user;
    localStorage.setItem(USER, user);
  }

  setToken(token: string) {
    this.token = token;
    localStorage.setItem(TOKEN, token);
  }

  logout() {
    localStorage.clear();
    this.user = null;
    this.token = null;
  }
}
