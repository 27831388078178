import { Component, OnInit } from '@angular/core';
import { LandService } from '../services/land.service';
import { AgriexpertsService } from '../services/agriexperts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-listcrop',
  templateUrl: './listcrop.component.html',
  styleUrls: ['./listcrop.component.scss'],
})
export class ListcropComponent implements OnInit {
  mycrop; // all crop list is here
  singleCrop; // single crop data is here
  allusers; // all users of platform
  cropProgrssData; // crop progress report
  bookedcropsData;
  progrssUpdateDiv = false;
  bookCropedDiv = true;
  bookCropedSingleData;
  cropPage = true;
  submitted = false;
  userDivDisplay = true;
  paymentAmount;
  BroughtFrom;
  updateCropId;
  billImage;
  cropImages = [];
  cropImagesDisplay = [];
  updateCropProgressForm: FormGroup;
  addCropProgressReportForm: FormGroup;
  addProgrss;
  searchTagdata;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private landservice: LandService,
    private toastr: ToastrService,
    private agriexpertservice: AgriexpertsService,
  ) {}

  ngOnInit() {
    this.updateCropProgressForm = this.formBuilder.group({
      crop_id: ['', Validators.required],
      seeds_fertilizers_bought: [''],
      bought_from_user_id: [''],
      bought_from_others: [''],
      bought_from: [''],
      bought_bill_image: [''],
      sowing_validated: [''],
      sowing_validated_day: [''],
      crop_images: [''],
      pesticides_applied: [''],
      pesticides_applied_days: [''],
      water_fulfilled: [''],
      pesticides_type: [''],
      pesticides_applied_quantity: [''],
      pesticides_description: [''],
      curating_initiated: [''],
      searchTagdata: [''],
    });

    this.addCropProgressReportForm = this.formBuilder.group({
      crop_id: ['', Validators.required],
      soil_health_check: ['', Validators.required],
    });

    this.getListCrop();
    // this.getbookedcrops();
  }

  onKey() {
    this.userDivDisplay = true;
    console.log('searchTagdata');
    this.searchTagdata = this.updateCropProgressForm.get('searchTagdata').value;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        this.billImage = e.target.result;
      };
    }
  }

  onFileChangeCropImages(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        let img = e.target.result.split('base64,', 2);
        if (this.cropImages.length > 0) {
          this.cropImages[this.cropImages.length] = img[1];
          this.cropImagesDisplay[this.cropImagesDisplay.length] = e.target.result;
        } else {
          this.cropImages[0] = img[1];
          this.cropImagesDisplay[0] = e.target.result;
        }
      };
    }
  }

  getListCrop() {
    this.cropPage = true;
    this.singleCrop = null;
    this.landservice.getAllCrop().subscribe((res: any) => {
      console.log(res.data);
      this.mycrop = res.data;
    });
  }

  getCropById(id) {
    this.cropPage = false;
    this.landservice.getCropById({ crop_id: id }).subscribe(
      (res: any) => {
        this.singleCrop = res.data;
        this.getProgressRrportForDisplay();
        // getProgressReport
        console.log(this.singleCrop);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  updateProgressReport() {
    this.progrssUpdateDiv = true;
    this.getProgressReport();
  }

  BroughtFromDropDown() {
    this.BroughtFrom = this.updateCropProgressForm.get('bought_from').value;
    if (this.BroughtFrom == 'Vof') {
      this.getAllUsers();
    }
  }

  get f() {
    return this.updateCropProgressForm.controls;
  }

  onCropProgressUpdate() {
    this.updateCropProgressForm.patchValue({ crop_id: this.singleCrop.id });

    if (this.cropImages.length > 2) {
      this.updateCropProgressForm.patchValue({ crop_images: this.cropImages.toString() });
    }

    if (this.billImage) {
      const img = this.billImage.split('base64,', 2);
      this.updateCropProgressForm.patchValue({ bought_bill_image: img[1] });
    }

    var datePipe = new DatePipe('en-US');
    this.updateCropProgressForm.patchValue({
      sowing_validated_day: datePipe.transform(this.updateCropProgressForm.get('sowing_validated_day').value, 'dd-MM-yyyy'),
    });

    this.updateCropProgressForm.patchValue({
      pesticides_applied_days: datePipe.transform(this.updateCropProgressForm.get('pesticides_applied_days').value, 'dd-MM-yyyy'),
    });

    console.log(this.updateCropProgressForm.value);

    this.submitted = true;
    if (this.updateCropProgressForm.invalid) {
      return;
    }
    this.landservice.updateProgressReport(this.updateCropProgressForm.value).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });

        this.getProgressRrportForDisplay();
      },
      (err) => {
        console.log(err);
      },
    );
  }

  addProgrssReport() {
    this.addCropProgressReportForm.patchValue({ crop_id: this.singleCrop.id });
    this.submitted = true;
    if (this.addCropProgressReportForm.invalid) {
      return;
    }
    this.landservice.addProgressReport(this.addCropProgressReportForm.value).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.getProgressReport();
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getProgressReport() {
    this.landservice.getProgressReport({ crop_id: this.singleCrop.id }).subscribe(
      (res: any) => {
        console.log(res);
        this.addProgrss = false;
      },
      (err) => {
        this.addProgrss = true;
        console.log(err);
      },
    );
  }

  getAllUsers() {
    this.agriexpertservice.getAllUsers().subscribe(
      (res: any) => {
        console.log(res);
        this.allusers = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  selectUser(users) {
    console.log(users);
    this.updateCropProgressForm.patchValue({ bought_from_user_id: users.id, bought_from: '' });
    this.updateCropProgressForm.patchValue({ searchTagdata: users.name });
    this.userDivDisplay = false;
    console.log(this.updateCropProgressForm);
  }

  getProgressRrportForDisplay() {
    this.landservice.getProgressReport({ crop_id: this.singleCrop.id }).subscribe(
      (res: any) => {
        this.cropProgrssData = res.data;
        console.log(this.cropProgrssData);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  deleteCrop() {
    this.landservice.deleteCrop({ id: this.singleCrop.id }).subscribe((res: any) => {
      this.getListCrop();
      this.cropPage = true;
      this.singleCrop = null;
      this.toastr.success(res.message, 'success', {
        timeOut: 9000,
      });
    });
  }

  getbookedcrops() {
    this.landservice.getBookCrop().subscribe(
      (res: any) => {
        this.bookedcropsData = res.data;
        console.log(this.bookedcropsData);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  clickOnBookCrop(data) {
    this.bookCropedDiv = false;
    this.bookCropedSingleData = data;
    this.landservice.getProgressReport({ crop_id: data.book.cropid }).subscribe(
      (res: any) => {
        this.cropProgrssData = res.data;
        this.paymentAmount = this.bookCropedSingleData.book.amount * this.bookCropedSingleData.book.quantity;
        console.log(this.paymentAmount);
      },
      (err) => {
        console.log(err);
      },
    );
  }
}
