import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
})
export class KycComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  salutation = ['Ms', 'Mrs', 'Mr'];
  marital_status = ['Single', 'Married'];
  account_type = ['Saving', 'Current'];
  id_imageBit;
  image_with_idBit;
  kyc_Message;

  constructor(
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private toastr: ToastrService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      salutation: ['', Validators.required],
      marital_status: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      id_number: ['', Validators.required],
      id_image: ['', Validators.required],
      image_with_id: ['', Validators.required],
      // bank_name: ['', Validators.required],
      // account_type: ['', Validators.required],
      // account_number: ['', Validators.required],
      // ifsc: ['', Validators.required],
      // swift_code: ['', Validators.required],
    });
    this.onGet();
  }

  get f() {
    return this.profileForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    this.authservice.updateKyc(this.profileForm.value).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  onGet() {
    const data = this.userService.getUser();
    this.profileForm.patchValue({
      firstName: data.first_name,
      middleName: data.middle_name,
      lastName: data.last_name,
    });
    this.authservice.getKyc().subscribe(
      (res: any) => {
        this.profileForm.patchValue(res.data);
        console.log(res.data);
        if (res.data) {
          // this.kyc_Message = res.data.kyc_message;
          this.kyc_Message = 'kyc is pending';
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onFileChangeImageWithId(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        let img = e.target.result.split('base64,', 2);
        this.profileForm.patchValue({ image_with_id: img[1] });
      };
    }
  }

  onFileChangeImage(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        let img = e.target.result.split('base64,', 2);
        this.profileForm.patchValue({ id_image: img[1] });
      };
    }
  }
}
