import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LandService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  registerLand(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'registerland?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getLand() {
    return this.http.get(VofBackend.apiUrlbackend + 'registeredland?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getLandById() {
    return this.http.get(VofBackend.apiUrlbackend + 'registeredland?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  updateLand(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'updateland?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  deleteLand(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'deleteland?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  addLandReport(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'addlandreport?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getLandReport(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'landreport?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  updateLandReport(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updatelandreport?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getKrishitagyaList() {
    return this.http.get(VofBackend.apiUrlbackend + 'krishitagya?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  requestKrishitagya(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'makerequest?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getRequestFarmer() {
    return this.http.get(VofBackend.apiUrlbackend + 'getrequest?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getRequestFarmerByID(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'getrequestbyid?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getRequestKrishitagya() {
    return this.http.get(VofBackend.apiUrlbackend + 'krishirequests?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getRequestKrishitagyaByID(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'krishirequestsbyid?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  acceptRequestKrishitagya(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'acceptrequest?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  cancelRequestKrishitagya(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'cancelinspectionrequest?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  updateRequest(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updaterequest?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  // Start ride

  startRide(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'startride?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  rideStatus(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'ridestatus?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  verifyStatus(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'verifyotp?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  requestRating(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'ratekrishirequest?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  // crop api's

  listCrop(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'listcrop?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  updateCrop(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'updatecrop?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  deleteCrop(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'deletecrop?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getAllCrop() {
    return this.http.get(VofBackend.apiUrlbackend + 'listedcrop?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getCropById(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'cropbyid?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  addProgressReport(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'addcropprogress?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getProgressReport(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'cropprogress?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  updateProgressReport(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updatecropreport?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  cropValidation(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'cropvalidation?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getAllcropProgress() {
    return this.http.get(VofBackend.apiUrlbackend + 'allcropprogress?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  bookcrop(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'cropbook?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getBookCrop() {
    return this.http.get(VofBackend.apiUrlbackend + 'cropbook?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }
}
