import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LandService } from '../services/land.service';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-add-crops',
  templateUrl: './add-crops.component.html',
  styleUrls: ['./add-crops.component.scss'],
})
export class AddCropsComponent implements OnInit {
  cropForm: FormGroup;
  submitted: true;
  landdata;
  constructor(
    private formBuilder: FormBuilder,
    private landService: LandService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.cropForm = this.formBuilder.group({
      crop_name: ['', Validators.required],
      harvesting_date: ['', Validators.required],
      quantity: ['', Validators.required],
      selling_price: ['', Validators.required],
      crop_type: ['', Validators.required],
      sowing_date: ['', [Validators.required]],
      land_id: ['', Validators.required],
      unit: ['', [Validators.required]],
      crop_life_shell_days: ['', Validators.required],
      life_shell_description: ['', [Validators.required]],
      crop_id: [''],
    });

    this.route.queryParams
      .filter((params) => params.crop)
      .subscribe((params) => {
        this.getSingleCrop(params.crop);
      });

    this.getLand();
  }

  get f() {
    return this.cropForm.controls;
  }

  onSave() {
    this.submitted = true;
    if (this.cropForm.invalid) {
      return;
    }

    if (this.cropForm.get('crop_id').value) {
      this.landService.updateCrop(this.cropForm.value).subscribe(
        (res: any) => {
          this.router.navigate(['/listcrop']);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      this.landService.listCrop(this.cropForm.value).subscribe(
        (res: any) => {
          this.router.navigate(['/listcrop']);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
    }
  }

  getLand() {
    this.landService.getLand().subscribe(
      (res: any) => {
        this.landdata = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  updateCrop() {
    this.landService.updateCrop(this.cropForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
        console.log(err);
      },
    );
  }

  deleteCrop() {
    this.landService.deleteCrop(this.cropForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  getSingleCrop(id) {
    this.landService.getCropById({ crop_id: id }).subscribe(
      (res: any) => {
        console.log(res);
        this.cropForm.patchValue(res.data);
        this.cropForm.patchValue({ land_id: res.data.land.land_id });
        this.cropForm.patchValue({ crop_id: res.data.id });
      },
      (err) => {
        console.log(err);
      },
    );
  }
}
