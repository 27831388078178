import { Component, OnInit } from '@angular/core';
import { DiscussionFormService } from '../services/discussion-form.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { id } from 'ethers/utils';

@Component({
  selector: 'app-vofqa',
  templateUrl: './vofqa.component.html',
  styleUrls: ['./vofqa.component.scss'],
})
export class VofqaComponent implements OnInit {
  forumsdata;
  myAnswersData;
  forumForm: FormGroup;
  submitted = false;
  questionEdit = false;
  newForum;
  tags = [];
  searchTagdata;
  imagedata;
  myanswersUpdate;
  myanswerUpdateData;

  updateAnswer = { comment: '', id: '' };

  // tslint:disable-next-line: max-line-length
  constructor(
    private discussionservice: DiscussionFormService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.forumForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required],
      tagsarr: [[]],
      tags: ['', Validators.required],
      id: [''],
    });
    this.forumList();
  }

  get f() {
    return this.forumForm.controls;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        console.log(e.target.result);
        this.forumForm.patchValue({ image: e.target.result });
        this.imagedata = e.target.result;
      };
    }
  }

  onClickNewQ() {
    this.newForum = true;
    this.myanswersUpdate = false;
    console.log('new question');
  }

  onSubmit() {
    // tslint:disable-next-line: prefer-const
    if (this.imagedata) {
      let img = this.imagedata.split('base64,', 2);
      this.forumForm.patchValue({ image: img[1] });

      for (let i = 0; i < this.forumForm.value.tagsarr.length; i++) {
        this.tags[i] = this.forumForm.value.tagsarr[i].displayValue;
      }
      // tslint:disable-next-line: prefer-const
      let stringdata = this.tags.toString();
      this.forumForm.patchValue({ tags: stringdata });
    }

    this.submitted = true;
    console.log(this.forumForm.value);
    if (this.forumForm.invalid) {
      return;
    }

    if (this.questionEdit) {
      this.discussionservice.updateForum(this.forumForm.value).subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
          this.newForum = false;
          this.myanswersUpdate = false;
          this.forumList();
        },
        (err) => {},
      );
    } else {
      console.log('hello calling');
      this.discussionservice.AddForum(this.forumForm.value).subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
          this.newForum = false;
          this.myanswersUpdate = false;
          this.forumList();
        },
        (err) => {
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
    }
  }

  forumList() {
    this.discussionservice.getlistForm().subscribe(
      (res: any) => {
        this.forumsdata = res.data;
        console.log(this.forumsdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onTagsChanged(event) {}

  onSearch() {}

  onClickTag(tagsdata) {
    this.searchTagdata = tagsdata;
  }

  onAllQuestion() {
    this.forumList();
    this.newForum = false;
    this.myanswersUpdate = false;
  }

  onMyQuestions() {
    this.newForum = false;

    this.myanswersUpdate = false;

    this.discussionservice.myForum().subscribe(
      (res: any) => {
        this.forumsdata = res.data;
        console.log(this.forumsdata);
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }
  // tslint:disable-next-line: no-shadowed-variable
  onQuestionUpdate(id: any) {
    this.newForum = true;
    this.myanswersUpdate = false;
    this.getForumByID(id);
  }
  onMyAnswers() {
    this.newForum = false;
    this.myanswersUpdate = false;

    this.discussionservice.myAnswers().subscribe(
      (res: any) => {
        this.myAnswersData = res.data;
        console.log(this.myAnswersData);
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  // tslint:disable-next-line: no-shadowed-variable
  getForumByID(id: any) {
    this.discussionservice.getFoumById(id).subscribe(
      (res: any) => {
        this.forumForm.patchValue(res.forum);
        this.forumForm.patchValue({ tagsarr: res.forum.tags });
        this.questionEdit = true;
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  onQuestionDelete() {
    this.discussionservice.deletemyForum(this.forumForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.forumForm.patchValue({});
        this.newForum = false;
        this.myanswersUpdate = false;
        this.forumList();
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  MyAnswerUpdate(data) {
    this.newForum = false;
    this.myanswersUpdate = true;
    this.myanswerUpdateData = data;
    this.updateAnswer.id = data.comment_id;
    this.updateAnswer.comment = data.comment;
  }

  onAnswerUpdate() {
    this.discussionservice.updatecomment(this.updateAnswer).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.onMyAnswers();
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }
  onAnswerDelete() {
    this.discussionservice.deleteComment(this.updateAnswer.id).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.onMyAnswers();
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  onCancel() {
    this.newForum = false;
    this.myanswersUpdate = false;
  }
}
