import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AgriexpertsService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getFarmerList() {
    return this.http.get(VofBackend.apiUrlbackend + 'farmers?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getAgriExpertsList() {
    return this.http.get(VofBackend.apiUrlbackend + 'agriexperts?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  createThreadChat(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'chatthread?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getChatThread() {
    return this.http.get(VofBackend.apiUrlbackend + 'chatthreads?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  ratingsAndFeedback(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'ratings?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  sendMessage(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'message?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getMessage(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'threadmessages?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  readMessage(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'read?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getallFeedback(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'user_ratings?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getAllUsers() {
    return this.http.get(VofBackend.apiUrlbackend + 'getallusers?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  // krishitagya apis status

  getkrishiStatus() {
    return this.http.get(VofBackend.apiUrlbackend + 'status?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  updateKrishiStatus() {
    return this.http.get(VofBackend.apiUrlbackend + 'update_status?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getKrishitagyaList() {
    return this.http.get(VofBackend.apiUrlbackend + 'krishitagya?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }
}
