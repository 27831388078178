import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CountrycityService } from '../services/countrycity.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-adddeliveryaddress',
  templateUrl: './adddeliveryaddress.component.html',
  styleUrls: ['./adddeliveryaddress.component.scss'],
})
export class AdddeliveryaddressComponent implements OnInit {
  selectedCountry = '--Choose Country--';
  userdata = { country: '', state: '', city: '', address: '', secondary_email: '', profile_image: '' };
  constructor(
    private countryService: CountrycityService,
    private formBuilder: FormBuilder,
    private productservice: ProductService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  countries: any;
  states: any;

  addressForm: FormGroup;
  addressData;
  submitted = false;
  countrydata;
  lat;
  lng;

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      landmark: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      email_address: ['', Validators.required],
      mobile_number: ['', Validators.required],
      postal_code: ['', Validators.required],
      co_ordinates: ['', Validators.required],
      id: [''],
    });

    this.countries = this.countryService.populateCountries();

    this.getAddress();
  }

  get f() {
    return this.addressForm.controls;
  }

  public onCountryChange(event): void {
    // event will give you full breif of action
    // console.log('in onCountryChange event ', event);
    const part = event.target.value.split('$');
    const newVal = part[0];
    this.states = this.countryService.populateStates(newVal);
    // console.log('states values: ', this.states);
  }

  onSubmit() {
    this.submitted = true;
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      });
    }

    this.addressForm.patchValue({ co_ordinates: this.lat + ',' + this.lng });

    console.log(this.addressForm.value);

    if (this.addressForm.invalid) {
      return;
    }

    console.log(this.addressForm.get('id').value);

    if (this.addressForm.get('id').value) {
      this.productservice.updateDeleveryAddress(this.addressForm.value).subscribe(
        (res: any) => {
          this.getAddress();
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      this.productservice.addDeleveryAddress(this.addressForm.value).subscribe(
        (res: any) => {
          console.log(res);
          this.getAddress();
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
    }

    if (this.addressForm.valid) {
      this.submitted = false;
      this.addressForm.reset();
    }
  }

  // changeCountry(country) {
  //   this.countrydata = country;
  //   this.states = this.Countries.find(cntry => cntry.name == country).states;
  // }
  // changeState(state) {
  //   this.cities = this.Countries.find(cntry => cntry.name == this.countrydata).states.find(stat => stat.name == state).cities;
  // }

  getAddress() {
    this.productservice.getDeleveryAddress().subscribe(
      (res: any) => {
        // this.addressForm.patchValue(res.data[0]);
        this.addressData = res.data;
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onDelete(data) {
    this.productservice.deleteDeleveryAddress({ id: data.address_id }).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.getAddress();
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onEdit(data) {
    console.log(data);
    this.addressForm.patchValue(data);
    this.addressForm.patchValue({ id: data.address_id });
    console.log(this.addressForm.value);
    console.log(this.addressForm.get('id').value);
  }
}
