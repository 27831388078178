import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscussionFormService } from '../services/discussion-form.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-discussionside',
  templateUrl: './discussionside.component.html',
  styleUrls: ['./discussionside.component.scss'],
})
export class DiscussionsideComponent implements OnInit {
  submitted = false;
  id;
  forumData;
  commentData;
  Profile;
  // tslint:disable-next-line: variable-name
  profile_image: any;
  newAnswerForm: FormGroup;
  modal;

  // tslint:disable-next-line: max-line-length
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private discussionservice: DiscussionFormService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.newAnswerForm = this.formBuilder.group({
      forum_id: ['', Validators.required],
      comment: ['', Validators.required],
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.Profile = JSON.parse(localStorage.getItem('user'));
    console.log(this.Profile);
    this.getForumById();
  }

  get f() {
    return this.newAnswerForm.controls;
  }

  getForumById() {
    this.discussionservice.getFoumById(this.id).subscribe(
      (res: any) => {
        this.forumData = res;
        console.log(this.forumData.user_voted);
      },
      (err) => {
        console.log(err);
      },
    );
  }
  onNewAnswer() {}

  onSubmit() {
    this.newAnswerForm.patchValue({ forum_id: this.id });
    this.submitted = true;
    if (this.newAnswerForm.invalid) {
      return;
    }

    this.discussionservice.forumComment(this.newAnswerForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 3000,
        });
        this.getForumById();
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onQuestionLike(data) {
    // tslint:disable-next-line: triple-equals
    console.log(data);
    // if (data.user_voted == 'False') {
    this.discussionservice.forumvote({ id: data.forum.id }).subscribe(
      (res: any) => {
        console.log(res);
        this.getForumById();
      },
      (err) => {
        console.log(err);
      },
    );
    // }
  }

  onAnswerLike(data) {
    console.log(data);
    // tslint:disable-next-line: triple-equals
    // if (data.commentuservoted == 'False') {
    this.discussionservice.commentVote({ id: data.id }).subscribe(
      (res: any) => {
        console.log(res);
        this.getForumById();
      },
      (err) => {
        console.log(err);
      },
    );
    // }
  }
}
