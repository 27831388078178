import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { LandService } from '../services/land.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getLocation, toBase64 } from '../lib/utils';
import { base64 } from 'ethers/utils';

declare var google: any;

@Component({
  selector: 'app-listland',
  templateUrl: './listland.component.html',
  styleUrls: ['./listland.component.scss'],
})
export class ListlandComponent implements OnInit {
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;
  landDataForm: FormGroup;
  requestKrishitagyaFrom: FormGroup;
  landreportUpdateInitForm: FormGroup;
  acceptRequestForm: FormGroup;
  cancelFarmerRequestForm: FormGroup;
  cancelKrishitagyaRequestForm: FormGroup;
  listlandForm: FormGroup;
  rideRating = { request_id: '', ratings: '', feedback: '' };
  verifyData = { request_id: '', otp: '' };
  ridestatus;
  imagesofLand = [];
  imagesofLandDisplay = [];

  allKrishitagya;
  farmerRequestInitiated;
  krishitagyaRequestsaccess;
  soiltestinitDeatils = false;
  soiltestinit = true;
  soiltestreq = true;
  soiltestinitDeatilsData;
  soiltestRequestDetails = false;
  soiltestReqDeatils = false;
  soiltestRequestData;
  viewRideStatus = false;
  updateLandReportpage = false;
  krishitagyareportupdateDiv = false;
  viewRideStatusData;
  showMapLocation = false;
  MyLandDetailPage = false;
  MyLandMAinPage = true;
  lands;

  ampm;
  timestamp = ['AM', 'PM'];
  submittedrequestKrishitagya = false;
  submitted = false;

  constructor(
    private router: Router,
    private landservice: LandService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
  ) {}

  myland;
  data = { id: '' };
  lat: number;
  lng: number;
  zoom: number;
  // address: string;
  private geoCoder;

  landidforUpdate;

  ngOnInit() {
    this.listlandForm = this.formBuilder.group({
      id: [''],
      location: ['', Validators.required],
      farm_name: ['', Validators.required],
      co_ordinates: ['', Validators.required],
      area: ['', Validators.required],
      unit: ['', Validators.required],
      image_proof: ['', Validators.required],
    });

    this.requestKrishitagyaFrom = this.formBuilder.group({
      land_id: ['', Validators.required],
      to_user_id: ['', Validators.required],
      expected_to_date: ['', Validators.required],
      expected_from_date: ['', Validators.required],
      expected_time_range: ['', Validators.required],
      time_pattern: ['', Validators.required],
      transportation_charge: ['', Validators.required],
    });

    this.landreportUpdateInitForm = this.formBuilder.group({
      land_id: ['', Validators.required],
      value_of_k: ['', Validators.required],
      value_of_p: ['', Validators.required],
      value_of_n: ['', Validators.required],
      value_of_c: ['', Validators.required],
      value_of_ph: ['', Validators.required],
      value_of_ec: ['', Validators.required],
      value_of_s: ['', Validators.required],
      value_of_zn: ['', Validators.required],
      value_of_b: ['', Validators.required],
      value_of_fe: ['', Validators.required],
      value_of_mn: ['', Validators.required],
      value_of_cu: ['', Validators.required],
      suggested_crop: ['', Validators.required],
      water_requirement: ['', Validators.required],
      land_images: ['', Validators.required],
    });

    this.acceptRequestForm = this.formBuilder.group({
      request_id: ['', Validators.required],
      arrival_date: ['', Validators.required],
      arrival_time: ['', Validators.required],
    });

    this.cancelFarmerRequestForm = this.formBuilder.group({
      request_id: ['', Validators.required],
      cancelled_reason: ['', Validators.required],
    });

    this.cancelKrishitagyaRequestForm = this.formBuilder.group({
      request_id: ['', Validators.required],
      cancelled_reason: ['', Validators.required],
    });

    this.getLand();
    this.getrequestFarmer();
    this.getrequestKrishitagya();
    this.setCurrentLocation();

    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder();

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //     types: ['address'],
    //   });
    //   autocomplete.addListener('place_changed', () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.lat = place.geometry.location.lat();
    //       this.lng = place.geometry.location.lng();
    //       this.zoom = 12;
    //     });
    //   });
    // });
  }

  // Get Current Location Coordinates
  private async setCurrentLocation() {
    try {
      const coords = await getLocation();
      this.lng = coords.lng;
      this.lat = coords.lat;
    } catch (e) {
      console.log(e);
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.listlandForm.patchValue({ location: results[0].formatted_address });
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  registerLand() {
    this.listlandForm.patchValue({ co_ordinates: this.lat + ',' + this.lng });
    this.submitted = true;
    if (this.listlandForm.invalid) {
      return;
    }

    // if(this.landdata.id){
    //   this.updateLand();
    // } else{
    console.log('insert');
    this.landservice.registerLand(this.listlandForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.getLand();
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
    this.getLand();
    // }
  }

  updateLand() {
    this.landservice
      .updateLand(this.listlandForm.value)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      toBase64(file)
        .then(base64 => {
          let img = base64.split('base64,', 2);
          this.listlandForm.patchValue({ image_proof: img[0] });
        })
      };
    }
  

  onFileChangeLandImages(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      toBase64(file).then(base64 => {
        let img = base64.split('base64,', 2);
        if (this.imagesofLand.length > 0) {
          this.imagesofLand[this.imagesofLand.length] = img[1];
          this.imagesofLandDisplay[this.imagesofLandDisplay.length] = base64;
        } else {
          this.imagesofLand[0] = img[1];
          this.imagesofLandDisplay[0] = base64;
        }
      });
    }
  }

  deleteLand() {
    this.landservice
      .deleteLand(this.data)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
          this.MyLandDetailPage = false;
          this.MyLandMAinPage = true;
          this.getLand();
        },
        (err) => {
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
    this.getLand();
  }

  getLand() {
    console.log('hello demo');
    this.landservice
      .getLand()
      .subscribe(
        (res: any) => {
          this.myland = res.data;
          console.log(this.myland);
        },
        (err) => {
          this.router.navigate(['/home']);
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  myFunction() {}

  onDelete(data) {
    this.data.id = data;
  }
  onUpdate(data) {
    this.listlandForm.patchValue(data);
  }

  requestKrishitagybutton() {
    this.soiltestReqDeatils = true;
    this.getAllKrishitagya();
  }

  addLandReport(data) {
    console.log(data);
    let str = this.imagesofLand.toString();
    this.landreportUpdateInitForm.patchValue({ land_images: str });
    this.landreportUpdateInitForm.patchValue({ land_id: data.land.farm_id });
    console.log(this.landreportUpdateInitForm.value);

    this.submitted = true;
    if (this.landreportUpdateInitForm.invalid) {
      return;
    }

    this.landservice.addLandReport(this.landreportUpdateInitForm.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );

    // this.updateLandReport();
  }

  getLandReport() {
    this.landservice.getLandReport({ land_id: this.soiltestinitDeatilsData.land.farm_id }).subscribe(
      (res: any) => {
        this.landidforUpdate = res.data.land_id;
        this.landreportUpdateInitForm.patchValue({ land_id: res.data.land_id });
        this.landreportUpdateInitForm.patchValue({ value_of_k: res.data.value_of_k });
        this.landreportUpdateInitForm.patchValue({ value_of_p: res.data.value_of_p });
        this.landreportUpdateInitForm.patchValue({ value_of_n: res.data.value_of_n });
        this.landreportUpdateInitForm.patchValue({ value_of_c: res.data.value_of_c });
        this.landreportUpdateInitForm.patchValue({ suggested_crop: res.data.suggested_crop });
        this.landreportUpdateInitForm.patchValue({ water_requirement: res.data.water_requirement });
        this.landreportUpdateInitForm.patchValue({ land_images: res.data.land_images });
        this.imagesofLandDisplay = res.data.land_images;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  updateReportButton() {
    this.updateLandReportpage = true;
    this.getLandReport();
  }

  updateLandReport() {
    this.landreportUpdateInitForm
      .patchValue({ land_id: this.landidforUpdate });
    this.landservice
      .updateLandReport(this.landreportUpdateInitForm.value)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  get f() {
    return this.requestKrishitagyaFrom.controls;
  }

  get flandreport() {
    return this.landreportUpdateInitForm.controls;
  }

  get facceptreport() {
    return this.acceptRequestForm.controls;
  }

  get fcancel() {
    return this.cancelKrishitagyaRequestForm.controls;
  }

  get flistland() {
    return this.listlandForm.controls;
  }

  krishitagyareportupdateDivm() {
    this.krishitagyareportupdateDiv = true;
  }

  requestKrishitagya(lands) {
    console.log(this.requestKrishitagyaFrom.value);
    this.submittedrequestKrishitagya = true;
    this.requestKrishitagyaFrom.patchValue({ land_id: lands.id });
    // this.requestKrishitagyaFrom.patchValue({'expected_time_range': this.requestKrishitagyaFrom.get('expected_time_range').value);

    if (this.requestKrishitagyaFrom.invalid) {
      return;
    }
    console.log(this.requestKrishitagyaFrom.value);

    var datePipe = new DatePipe('en-US');
    this.requestKrishitagyaFrom.patchValue({
      expected_from_date: datePipe.transform(this.requestKrishitagyaFrom.get('expected_from_date').value, 'dd-MM-yyyy'),
    });
    this.requestKrishitagyaFrom.patchValue({
      expected_to_date: datePipe.transform(this.requestKrishitagyaFrom.get('expected_to_date').value, 'dd-MM-yyyy'),
    });
    this.requestKrishitagyaFrom.patchValue({ to_user_id: '3' });

    this.landservice.requestKrishitagya(this.requestKrishitagyaFrom.value).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.krishitagyareportupdateDiv = false;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getAllKrishitagya() {
    // this.requestKrishitagyaData.land_id = id;
    let dataofuser = JSON.parse(localStorage.getItem('user'));
    let usercoordinates;
    usercoordinates = dataofuser.co_ordinates.split(',');
    this.landservice.getKrishitagyaList().subscribe(
      (res: any) => {
        this.allKrishitagya = res.data;
        console.log(this.allKrishitagya);
        let krishitagyacoordinates = this.allKrishitagya[0].co_ordinates.split(',');

        const userlatlong = new google.maps.LatLng(usercoordinates[0], usercoordinates[1]);
        const krishilatlong = new google.maps.LatLng(krishitagyacoordinates[0], krishitagyacoordinates[1]);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(userlatlong, krishilatlong);
        console.log(distance);
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  getrequestFarmer() {
    this.landservice
      .getRequestFarmer()
      .subscribe(
        (res: any) => {
          console.log(res);
          this.farmerRequestInitiated = res.data;
        },
        (err) => {
          console.log(err);
        },
      );
  }

  getrequestFarmerByID(data) {
    this.soiltestinit = false;
    this.landservice
      .getRequestFarmerByID({ request_id: data.id })
      .subscribe(
        (res: any) => {
          console.log(res.data);
          this.soiltestinitDeatilsData = res.data;
          this.soiltestinitDeatils = true;
          console.log(this.soiltestinitDeatilsData);
        },
        (err) => {
          console.log(err);
        },
      );
  }

  getrequestKrishitagya() {
    this.landservice
      .getRequestKrishitagya()
      .subscribe(
        (res: any) => {
          console.log(res);
          this.krishitagyaRequestsaccess = res.data;
        },
        (err) => {
          console.log(err);
        },
      );
  }

  getrequestKrishitagyaById(data) {
    this.landservice
    .getRequestKrishitagyaByID({ request_id: data })
    .subscribe(
      (res: any) => {
        console.log(res);
        this.soiltestinitDeatilsData = res.data;
        console.log(this.soiltestinitDeatilsData);
        this.soiltestReqDeatils = true;
        this.soiltestreq = false;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  cancelrequest(data) {
    console.log(data);
    this.cancelKrishitagyaRequestForm.patchValue({ request_id: data.id });
  }

  cancelRequestKrishitagya() {
    console.log(this.cancelKrishitagyaRequestForm.value);
    if (this.cancelKrishitagyaRequestForm.invalid) {
      return;
    }
    this.landservice
      .cancelRequestKrishitagya(this.cancelKrishitagyaRequestForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
          this.toastr.error(err.error.message, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  acceptRequest(id) {
    this.acceptRequestForm.patchValue({ request_id: id });
  }

  acceptrequestKrishitagya(data) {
    console.log(this.acceptRequestForm.get('arrival_date').value);
    this.submitted = true;
    if (this.acceptRequestForm.invalid) {
      return;
    }
    const datePipe = new DatePipe('en-US');
    const arrivalDate = datePipe.transform(this.acceptRequestForm.get('arrival_date').value, 'dd-MM-yyyy');
    this.acceptRequestForm.patchValue({ arrival_date: arrivalDate });
    console.log(this.acceptRequestForm.value);
    this.landservice
      .acceptRequestKrishitagya(this.acceptRequestForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
          this.toastr.error(err.error.status, 'Error', {
            timeOut: 9000,
          });
        },
      );
  }

  // Start Ride

  startRide(id) {
    this.landservice
      .startRide({ request_id: id })
      .subscribe(
        (res: any) => {
          console.log(res);
          this.ridestatus;
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
  }

  rideStatus(id) {
    console.log(id);
    this.viewRideStatus = true;
    this.landservice
      .rideStatus({ request_id: id })
      .subscribe(
        (res: any) => {
          this.viewRideStatusData = res.data;
          console.log(this.viewRideStatusData);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
  }

  verifyOtp(id) {
    this.verifyData.request_id = this.soiltestinitDeatilsData.id;
    this.landservice
      .verifyStatus(this.verifyData)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
  }

  requestRating(id) {
    this.rideRating.request_id = id;
    this.landservice
      .requestRating(this.rideRating)
      .subscribe(
        (res: any) => {
          console.log(res.data);
          this.toastr.success(res.message, 'success', {
            timeOut: 9000,
          });
        },
        (err) => {
          console.log(err);
        },
      );
  }

  openSidebar() {
    if (this.showMapLocation == false) {
      this.showMapLocation = true;
    } else {
      this.showMapLocation = false;
    }
  }
  closeModal() {}

  getLandDetailsPage(data) {
    this.lands = data;
    this.MyLandMAinPage = false;
    this.getAllKrishitagya();
    this.MyLandDetailPage = true;
  }

  closetab() {
    this.MyLandMAinPage = true;
    this.MyLandDetailPage = false;
  }
}
