import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { Coords } from "./interfaces";

export function handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: \n ${JSON.stringify(error.error,undefined,2)}`);
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}


export function getLocation() :Promise<Coords>{
  return new Promise((res,rej) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(postition => res({ lat: postition.coords.latitude,lng: postition.coords.longitude } as Coords));
    } else {
      rej("Geolocation is not supported by this browser.");
    }
  });
}

export const toBase64 = (file): Promise<string> => {
  return new Promise((res,rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      res(e.target.result);
    };
  })
}