import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolswappingService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  listProductRent(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'list?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  listedToolsforRent() {
    return this.http.get(VofBackend.apiUrlbackend + 'listed?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  myListedTools() {
    return this.http.get(VofBackend.apiUrlbackend + 'mylisted?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getListedProductById(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'listedbyid?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  updateProductById(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'updatebyid?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  deleteProductById(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'deletebyid?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  productOnRating(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'renting?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getDeliveryAddress() {
    return this.http.get(VofBackend.apiUrlbackend + 'getaddresses?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  addDeliveryAddress(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'addaddress?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  updateDeliveryAddress(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'updateaddress?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getRentInitiated() {
    return this.http.get(VofBackend.apiUrlbackend + 'myrentinitiated?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getRentInitiatedById(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'rentinitiatedbyid?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getMyRequest() {
    return this.http.get(VofBackend.apiUrlbackend + 'myrequests?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  getMyRequestById(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'myrequestbyid?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }
}
