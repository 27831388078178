import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-buyseedsandfertilizers',
  templateUrl: './buyseedsandfertilizers.component.html',
  styleUrls: ['./buyseedsandfertilizers.component.scss'],
})
export class BuyseedsandfertilizersComponent implements OnInit {

  imageShow;

  products;
  pdpData;
  quantity = 1;
  submitted = false;
  reviewInput = { selling_product_id: '', ratings: '', feedback: '' };
  reviewInputForm: FormGroup;
  reviewResult;
  productPage = true;
  detailsPage = false;

  constructor(
    private formBuilder: FormBuilder,
    private productservice: ProductService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.reviewInputForm = this.formBuilder.group({
      selling_product_id: ['', Validators.required],
      ratings: ['', Validators.required],
      feedback: ['', Validators.required],
    });
    this.getproducts();
  }

  getproducts() {
    this.productservice.getAllSellingProducts().subscribe(
      (res: any) => {
        this.products = res.data;
        // this.toastr.success(res.message, 'success', {
        //   timeOut: 9000
        // });
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onPDPpage(data) {
    console.log('calling pdppage');
    this.pdpData = data;
    console.log(this.pdpData);
    this.imageShow = data.images[0];
    this.productPage = false;
    this.detailsPage = true;
    this.productservice.getProductRating({ product_id: this.pdpData.id }).subscribe(
      (res: any) => {
        this.reviewResult = res.data;
        console.log(res.data);
      },
      (err) => {},
    );
  }

  showimage(index) {
    this.imageShow = this.pdpData.images[index];
  }

  onClickQuantityplus() {
    this.quantity++;
  }

  onClickQuantityminus() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  addToCart(data) {
    const cartdata = { selling_product_id: data.id, quantity: this.quantity };
    this.productservice.addToCart(cartdata).subscribe(
      (res: any) => {
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
        this.router.navigate(['/cart']);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  get f() {
    return this.reviewInputForm.controls;
  }

  reviewSubmit(pdpData) {
    this.reviewInputForm.patchValue({ selling_product_id: pdpData.id });
    console.log(this.reviewInputForm.value);
    this.submitted = true;
    if (this.reviewInputForm.invalid) {
      return;
    }
    this.productservice.productrating(this.reviewInputForm.value).subscribe(
      (res: any) => {
        this.productservice.getProductRating({ product_id: this.pdpData.id }).subscribe(
          (res: any) => {
            this.reviewResult = res.data;
            console.log(res.data);
          },
          (err) => {},
        );
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
      },
    );
  }
}
