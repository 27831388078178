import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PricePredictorService } from '../services/price-predictor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-price-prediction',
  templateUrl: './price-prediction.component.html',
  styleUrls: ['./price-prediction.component.scss'],
})
export class PricePredictionComponent implements OnInit {
  pricepredictorForm: FormGroup;
  submitted = false;
  predictedPrice = false;

  state = [
    {
      stateName: 'ANDHRA PRADESH',
      id: '0',
      crop: [
        { cropName: 'BRINJAL', id: '32' },
        { cropName: 'TAPIOCA', id: '75' },
        { cropName: 'BANANA-GREEN', id: '21' },
        { cropName: 'TOMATTO', id: '79' },
        { cropName: 'ONION', id: '63' },
        { cropName: 'CABBAGE', id: '33' },
        { cropName: 'BHINDI', id: '29' },
        { cropName: 'CAULIFLOWER', id: '38' },
        { cropName: 'BOTTLE GOURD', id: '31' },
        { cropName: 'YAM RATALU', id: '86' },
        { cropName: 'ASHGOURD', id: '15' },
        { cropName: 'CARROT', id: '36' },
        { cropName: 'BANANA', id: '20' },
        { cropName: 'BITTER GOURD', id: '30' },
        { cropName: 'AMBADA-SEED', id: '5' },
        { cropName: 'BEETROOT', id: '26' },
        { cropName: 'POTATO', id: '65' },
        { cropName: 'ARECANUT', id: '12' },
      ],
    },

    {
      stateName: 'ANDAMAN AND NICOBAR',
      id: '1',
      crop: [
        { cropName: 'TOMATTO', id: '79' },
        { cropName: 'WHEAT', id: '83' },
        { cropName: 'BOTTLE GOURD', id: '31' },
        { cropName: 'BEETROOT', id: '26' },
        { cropName: 'POTATO', id: '65' },
        { cropName: 'SAFFLOWER', id: '69' },
        { cropName: 'BRINJAL', id: '32' },
        { cropName: 'MOONG', id: '61' },
        { cropName: 'BANANA-GREEN', id: '21' },
        { cropName: 'GRAM', id: '49' },
        { cropName: 'URAD', id: '82' },
        { cropName: 'MAIZE', id: '57' },
        { cropName: 'GROUNDNUT', id: '50' },
        { cropName: 'BENGAL-GRAM(WHOLE0)', id: '28' },
        {
          cropName: 'RICE',
          id: '68',
        },
        { cropName: 'JUTE', id: '55' },
        { cropName: 'CHILLY CAPSICUM', id: '41' },
        { cropName: 'BITTER GOURD', id: '30' },
        { cropName: 'MASUR DAL', id: '58' },
        { cropName: 'CASHEWNUTS', id: '37' },
        { cropName: 'CAULIFLOWER', id: '38' },
        { cropName: 'COCONUT', id: '44' },
        { cropName: 'COTTON', id: '45' },
        { cropName: 'TURMERIC', id: '81' },
        { cropName: 'CHILI RED', id: '40' },
        { cropName: 'BENGAL-GRAM(CHANA DAL)', id: '27' },
        { cropName: 'BAMBOO', id: '19' },
        { cropName: 'JOWAR', id: '54' },
        { cropName: 'METHI SEEDS', id: '60' },
        { cropName: 'RAPESEED & MUSTARD', id: '67' },
        { cropName: 'LENTIL', id: '56' },
        { cropName: 'ARECANUT', id: '12' },
        { cropName: 'BEANS', id: '24' },
        { cropName: 'FIELD PEA', id: '46' },
        { cropName: 'CARROT', id: '36' },
        { cropName: 'BARLEY(JAU)', id: '22' },
        { cropName: 'CAPSICUM', id: '34' },
        { cropName: 'ARHAR', id: '13' },
        { cropName: 'APPLE', id: '10' },
        { cropName: 'RAGI', id: '66' },
        { cropName: 'MATAKI', id: '59' },
        { cropName: 'BAJRA', id: '17' },
        { cropName: 'CABBAGE', id: '33' },
        { cropName: 'ONION', id: '63' },
        { cropName: 'TAPIOCA', id: '75' },
        { cropName: 'GARLIC', id: '48' },
        { cropName: 'YAM', id: '85' },
        { cropName: 'BANANA', id: '20' },
        { cropName: 'TOBACCO', id: '78' },
        { cropName: 'SESAMUM', id: '70' },
        { cropName: 'SOYABEAN', id: '71' },
        { cropName: 'BHINDI', id: '29' },
        { cropName: 'NIGERSEED', id: '62' },
        { cropName: 'YAM RATALU', id: '86' },
        { cropName: 'SUNFLOWER', id: '73' },
        { cropName: 'TAMARIND', id: '74' },
        { cropName: 'PEAS', id: '64' },
        { cropName: 'AJWAN', id: '0' },
      ],
    },

    {
      stateName: 'ARUNACHAL PRADESH',
      id: '2',
      crop: [
        { cropName: ' TEA', id: '76' },
        { cropName: 'GROUNDNUT', id: 50 },
        { cropName: 'POTATO', id: '65' },
        { cropName: 'CLOVES', id: '43' },
        { cropName: 'CAULIFLOWER', id: '38' },
        { cropName: 'BOTTLE GOURD', id: '31' },
        { cropName: 'MAIZE', id: '57' },
        { cropName: 'BANANA', id: '20' },
        { cropName: 'BRINJAL', id: '32' },
        { cropName: 'ARHAR', id: '13' },
        { cropName: 'RICE', id: '68' },
        { cropName: 'GRAM', id: '49' },
        { cropName: 'BEATEN RICE', id: '25' },
        { cropName: 'URAD', id: '82' },
        { cropName: 'CARROT', id: '36' },
        { cropName: 'MASUR DAL', id: '58' },
        { cropName: 'RAPESEED & MUSTARD', id: '67' },
        { cropName: 'GARLIC', id: '48' },
        { cropName: 'BEANS', id: '24' },
        { cropName: 'APPLE', id: '10' },
        { cropName: 'MOONG', id: '61' },
        { cropName: 'CARDAMOMS', id: '35' },
        { cropName: 'CABBAGE', id: '33' },
        { cropName: 'BANANA-GREEN', id: '21' },
        { cropName: 'METHI SEEDS', id: '60' },
        { cropName: 'WHEAT', id: '83' },
        { cropName: 'CHILI RED', id: '40' },
        { cropName: 'BITTER GOURD', id: '30' },
        { cropName: 'ONION', id: '63' },
        { cropName: 'TOMATTO', id: '79' },
        { cropName: 'BHINDI', id: '29' },
        { cropName: 'CASHEWNUTS', id: '37' },
      ],
    },

    { stateName: 'ASSAM', id: '3' },
    { stateName: 'BIHAR', id: '4' },
    { stateName: 'CHHATTISGARH', id: '5' },
    { stateName: 'GOA', id: '6' },
    { stateName: 'GUJARAT', id: '7' },
    { stateName: 'HARYANA', id: '8' },
    { stateName: 'HIMACHAL PRADESH', id: '9' },
    { stateName: 'JAMMU AND KASHMIR', id: '10' },
    { stateName: 'JHARKHAND', id: '11' },
    { stateName: 'KARNATAKA', id: '12' },
    { stateName: 'KERALA', id: '13' },
    { stateName: 'MADHYA PRADESH', id: '14' },
    { stateName: 'MAHARASHTRA', id: '15' },
    { stateName: 'MANIPUR', id: '16' },
    { stateName: 'MIZORAM', id: '17' },
    { stateName: 'NAGALAND', id: '18' },
    { stateName: 'ODISHA', id: '19' },
    { stateName: 'PUNJAB', id: '20' },
    { stateName: 'RAJASTHAN', id: '21' },
    { stateName: 'SIKKIM', id: '22' },
    { stateName: 'TAMIL NADU', id: '23' },
    { stateName: 'TELANGANA', id: '24' },
    { stateName: 'TRIPURA', id: '25' },
    { stateName: 'UTTAR PRADESH', id: '26' },
    { stateName: 'UTTRAKHAND', id: '27' },
    { stateName: 'WEST BENGAL', id: '28' },
  ];

  crop: Array<any>;
  crop1 = ['1', '2', '3', '68', '25'];

  year = ['2020', '2021', '2022'];
  quarter = ['0', '1', '2', '3'];
  predictedPriceValue;
  selectedStateId;
  selectedCropId;
  constructor(private formBuilder: FormBuilder, private pricepredictorservice: PricePredictorService, private toastr: ToastrService) {}

  ngOnInit() {
    this.pricepredictorForm = this.formBuilder.group({
      state: [''],
      crop: [''],
      year: ['', Validators.required],
      quarter: ['', Validators.required],
    });
  }

  get f() {
    return this.pricepredictorForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.pricepredictorForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('state', this.selectedStateId);
    formData.append('crop', this.selectedCropId);
    formData.append('year', this.pricepredictorForm.value.year);
    formData.append('quarter', this.pricepredictorForm.value.quarter);
    this.pricepredictorservice.predict(formData).subscribe(
      (res: any) => {
        this.predictedPriceValue = res;
      },
      (err) => {
        this.predictedPriceValue = err.error.text;
      },
    );
  }

  predictionButton() {
    if (this.pricepredictorForm.status === 'VALID') {
      this.predictedPrice = true;
    }
  }

  changeCountry(state) {
    this.selectedStateId = this.state.find((statedata) => statedata.stateName == state).id;
    this.crop = this.state.find((statedata) => statedata.stateName == state).crop;
  }

  changeCrop(crop1) {
    let croptemp;
    croptemp = this.state.find((statedata) => statedata.id == this.selectedStateId).crop;
    this.selectedCropId = croptemp.find((cropdata) => cropdata.cropName == crop1).id;
    console.log(this.selectedCropId);
  }

  clearForm() {
    this.submitted = false;
    this.predictedPrice = false;
  }
}
