import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolswappingService } from '../services/toolswapping.service';
import { ProductService } from '../services/product.service';

declare var google: any;

@Component({
  selector: 'app-tool-swapping',
  templateUrl: './tool-swapping.component.html',
  styleUrls: ['./tool-swapping.component.scss'],
})
export class ToolSwappingComponent implements OnInit {
  ToolsDisplayPage = false;
  toolsListingPage = true;
  list = true;
  addtools = false;
  submitted = false;
  imagesofPRoducts = [];
  imagesofProductsForDisplay = [];
  country;
  allproducts;
  ToolDisplayData;
  myProducts;
  imageShow;
  productForm: FormGroup;
  rentForm: FormGroup;
  lat;
  lng;
  alltools = true;
  mytools = false;
  rentinit = false;
  myrequest = false;
  rentRequestData;
  rentInitialtedData;
  myRentRequestData;
  initclick = false;
  message;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toolswappingservice: ToolswappingService,
    private toastr: ToastrService,
    private productservice: ProductService,
  ) {}

  ngOnInit() {
    this.getList();
  }

  showimage(index) {
    this.imageShow = this.ToolDisplayData.product_images[index];
  }

  btnaddnewTools() {
    this.list = false;
    this.addtools = true;
    this.router.navigate(['/addtools']);
  }

  onRequestSubmit() {
    this.rentForm.patchValue({ listed_id: this.rentRequestData.id });

    if (this.rentForm.get('delivery_by_user_id').value == 'self') {
      this.rentForm.patchValue({ delivery_by_user_id: '' });
    }

    if (this.rentForm.get('delivery_by_user_id').value == 'owner') {
      this.rentForm.patchValue({ delivery_by_user_id: this.rentRequestData.user_id });
    }

    this.toolswappingservice.productOnRating(this.rentForm.value).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.success(res.message, 'success', {
          timeOut: 9000,
        });
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.message, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  getList() {
    this.toolswappingservice.listedToolsforRent().subscribe(
      (res: any) => {
        this.allproducts = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onproductClick(item, type) {
    this.router.navigate(['/toolsdetails', type, item.id]);
  }

  oninitProductClick(item, type) {
    this.router.navigate(['/toolsdetails', type, item.rent_id]);
  }
  onreqProductClick(item, type) {
    this.router.navigate(['/toolsdetails', type, item.product_id]);
  }

  onUpdate(data) {
    this.router.navigate(['/addtools']);
  }

  allTools() {
    this.alltools = true;
    this.mytools = false;
  }

  myTools() {
    this.alltools = false;
    this.mytools = true;

    this.toolswappingservice.myListedTools().subscribe(
      (res: any) => {
        this.myProducts = res.data;
      },
      (err) => {
        this.toastr.error(err.error.status, 'Error', {
          timeOut: 9000,
        });
      },
    );
  }

  getRentInitiated() {
    this.toolswappingservice.getRentInitiated().subscribe(
      (res: any) => {
        console.log(res.data);
        this.rentInitialtedData = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getMyRequest() {
    this.alltools = false;
    this.mytools = false;
    this.rentinit = false;
    this.myrequest = true;
    this.toolswappingservice.getMyRequest().subscribe(
      (res: any) => {
        console.log(res.data);
        this.myRentRequestData = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }
}
