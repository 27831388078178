import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookwarehouse',
  templateUrl: './bookwarehouse.component.html',
  styleUrls: ['./bookwarehouse.component.scss'],
})
export class BookwarehouseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
