import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { Tutorial } from '../models/userprofile';
import { AppState } from './../app.state';

declare var google: any;

@Component({
  selector: 'app-addsellingproduct',
  templateUrl: './addsellingproduct.component.html',
  styleUrls: ['./addsellingproduct.component.scss'],
})
export class AddsellingproductComponent implements OnInit {
  productForm: FormGroup;
  submitted = false;
  imagesofPRoducts = [];
  imagesofProductsForDisplay = [];
  tutorials: Observable<Tutorial[]>;
  lat;
  lng;
  constructor(
    private formBuilder: FormBuilder,
    private productservice: ProductService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
  ) {
    let dataofobservable;
    store.select('tutorial').subscribe((data) => {
      dataofobservable = data;
    });
    console.log(dataofobservable);
  }

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      title: ['', Validators.required],
      price: ['', Validators.required],
      price_type: ['', Validators.required],
      address: ['', Validators.required],
      co_ordinates: ['', Validators.required],
      description: ['', Validators.required],
      quantity: ['', Validators.required],
      images: ['', Validators.required],
      imgLength: ['', Validators.required],
    });

    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;

        var latlng = new google.maps.LatLng(this.lat, this.lng);
        var geocoder = (geocoder = new google.maps.Geocoder());
        geocoder.geocode({ latLng: latlng }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              console.log(results[1]);
              this.landdata.location = results[1].formatted_address;
            }
          }
        });
      });
    }
  }

  get f() {
    return this.productForm.controls;
  }

  onSubmit() {
    console.log(this.imagesofPRoducts.length);
    if (this.imagesofPRoducts.length > 2) {
      this.productForm.patchValue({ imgLength: this.imagesofPRoducts.length });
    }
    let str = this.imagesofPRoducts.toString();
    this.productForm.patchValue({ images: str });
    this.productForm.patchValue({ co_ordinates: this.lat + ',' + this.lng });
    this.submitted = true;
    if (this.productForm.invalid) {
      return;
    }
    this.productForm.get('imgLength').disable();
    console.log(this.productForm);
    this.productservice.addSellingProducts(this.productForm.value).subscribe(
      (res: any) => {
        this.router.navigate(['/buyseeds']);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        let img = e.target.result.split('base64,', 2);
        if (this.imagesofPRoducts.length > 0) {
          this.imagesofPRoducts[this.imagesofPRoducts.length] = img[1];
          this.imagesofProductsForDisplay[this.imagesofProductsForDisplay.length] = e.target.result;
        } else {
          this.imagesofPRoducts[0] = img[1];
          this.imagesofProductsForDisplay[0] = e.target.result;
        }
      };
    }
  }
}
