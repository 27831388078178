import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchData',
})
export class SearchDataPipe implements PipeTransform {
  transform(items: any[], searchTagdata: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchTagdata) {
      return items;
    }
    searchTagdata = searchTagdata.toLowerCase();
    return items.filter((it) => {
      // console.log(it);
      if (it.title.includes(searchTagdata) || it.tags.includes(searchTagdata)) {
        return it;
      }
    });
  }
}
