import { Component, Input, OnInit,  Output, EventEmitter  } from '@angular/core';
import { base64 } from 'ethers/utils';

@Component({
  selector: 'app-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss']
})
export class InputImageComponent implements OnInit {
  @Input() name: string = '';
  @Input() rounded: boolean = false;
  @Input() style: string = '';
  @Output() fileReadEvent = new EventEmitter<string>();

  fileUrl: string = '';

  constructor() { }

  ngOnInit() {
  }

  onChange($event) {
    this
      .encodeImageFileAsURL($event.target.files[0])
      .then(base64 => {
        this.fileUrl = base64;
        this.fileReadEvent.emit(base64);
      });

  }

  encodeImageFileAsURL(file): Promise<string> {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.onloadend = function() {
        res(reader.result.toString());
      }
      reader.readAsDataURL(file);
    })

  }

}
