// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const VofBackend = {
  // apiUrlbackend: 'http://3.19.194.120/api/'
  // apiUrlbackend:'http://localhost:8000/api/'
  apiUrlbackend: 'https://apis.valueoffarmers.org/api/',
  // apiUrlbackend: 'http://3.19.194.120/api/'
};

export const pricePredictor = {
  apiUrlbackend: 'https://apis.valueoffarmers.org/api/',
};

export const agmKey = {
  key: 'AIzaSyCRkgJBi2pYp7g3w4DksXuhVSCpidUDl4A',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
