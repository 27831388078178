import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VofBackend } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getAllSellingProducts() {
    return this.http.get(VofBackend.apiUrlbackend + 'getproducts?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  addSellingProducts(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'sellproducts?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  mySellingProducts() {
    return this.http.get(VofBackend.apiUrlbackend + 'myproducts?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  editMySellingProduct(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'sellproducts?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  rateSellingProducts(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'productrate?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getSellingProductRating(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'productratings?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  addToCart(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'addcart?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getCart() {
    return this.http.get(VofBackend.apiUrlbackend + 'cart?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  updateCart(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'updatecart?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  deleteCartItems(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'deletecart?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  addDeleveryAddress(data) {
    return this.http.post(VofBackend.apiUrlbackend + 'addaddress?token=' + localStorage.getItem('token'), data).pipe(map(this.extractData));
  }

  getDeleveryAddress() {
    return this.http.get(VofBackend.apiUrlbackend + 'getaddresses?token=' + localStorage.getItem('token')).pipe(map(this.extractData));
  }

  updateDeleveryAddress(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'updateaddress?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  deleteDeleveryAddress(data) {
    // tslint:disable-next-line: max-line-length
    return this.http
      .post(VofBackend.apiUrlbackend + 'deleteaddress?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  productrating(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'productrate?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }

  getProductRating(data) {
    return this.http
      .post(VofBackend.apiUrlbackend + 'productratings?token=' + localStorage.getItem('token'), data)
      .pipe(map(this.extractData));
  }
}
